import React from 'react';
import Layout   from '../components/common/layout';
import Footer   from '../components/common/Footer';
import Sidebar  from '../components/common/Sidebar';

import img2 from "../images/help-img-1.svg";
import img3 from "../images/help-img-2.svg";
import img1 from "../images/help-img-3.svg";
import img4 from "../images/help-img-4.svg";
import Header from '../components/common/Header';
import Notice      from '../components/common/notice';

import Introduction from '../components/introduction/Introduction';
//import Features     from '../components/common/featureSlider';
import Features from '../components/features/Features';
import Gallery from '../components/common/Gallery'
import TextField from '@material-ui/core/TextField';
import ContactForm from '../components/common/contactform';
import { Helmet } from "react-helmet";
const Help = () => {
    return(
      <Layout>
      <Header />
        <Helmet>
            <meta charset="utf-8" />
            <meta http-equiv="X-UA-Compatible" content="IE=edge" />       
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=yes" />
            <meta name="author" content="LaundryToGo - We Make Laundry Easy" />
            <meta name="description" content="Frequently Asked Questions About LaundryToGo Service." />
            <title>LaundryToGo - Help</title>
        </Helmet>
      <div id="wrapper" style={{color: "#000"}}>
        <div className="container" style={{padding: "80px 20px",display: 'flex',justifyContent: 'center'}}>
            <div className="row">
                <div className="col-md-12" style={{padding: "0px"}}>
                    <h3 className="help-heading contactForm contactHeading">Questions?</h3>
                    <ContactForm />
                </div>
            </div>
        </div>
        {/* Start */}
        <div className="help-wrapper" style={{background: "#000",color: "#fff"}}>
       <div className="container faq-container" style={{padding: "40px 20px"}}>
                <div className="row">
                    <div className="col-md-8">
                    <h3 className="help-heading title" style={{color: "#fff"}}>FAQ</h3>
                    <h5 className="help-heading question" style={{color: "#fff"}}>How does payment work?</h5>
                    </div>
                    <div className="col-md-8 answer">
                        <p>You can pay after we drop off your laundry. We accept any kind of credit card. We will pick up your clothes and send you an email invoice based on total weight of your laundry. Costs are calculated based on $1.89/lb, plus $9.99 for service and delivery. 
                            You can pay via the email invoice we send to you.</p>
                    </div>
                </div>
            </div>
            <div className="container faq-container" style={{padding: "40px 20px"}}>
                <div className="row">
                    <div className="col-md-8">
                    <h5 className="help-heading question" style={{color: "#fff"}}>Where do my clothes get picked up and dropped off?</h5>
                    </div>
                    <div className="col-md-8 answer">
                        <p>We come to you. Please include your address and any details during scheduling. The driver will call you to confirm details before heading your
                            way for pick up and drop-off. Contactless pick up and drop off available.
                        </p>
                    </div>
                </div>
            </div>
            <div className="container faq-container" style={{padding: "40px 20px"}}>
                <div className="row">
                    <div className="col-md-8">
                    <h5 className="help-heading question" style={{color: "#fff"}}>How long does it take to bring back my clothes?</h5>
                    </div>
                    <div className="col-md-8 answer">
                        <p>We do same day service and try our best to bring your clean clothes back to you within 3 hours.</p>
                    </div>
                </div>
            </div>
            <div className="container faq-container" style={{padding: "40px 20px"}}>
                <div className="row">
                    <div className="col-md-8">
                    <h5 className="help-heading question" style={{color: "#fff"}}>Do you also provide dry cleaning or ironing services?</h5>
                    </div>
                    <div className="col-md-8 answer">
                        <p>Not at this time. We will start providing dry cleaning and ironing services in the near future. Right now, we are a full-service wash, dry, fold, sort
                            laundry provider with pick up and delivery.</p>
                    </div>
                </div>
            </div>
            <div className="container faq-container" style={{padding: "40px 20px"}}>
                <div className="row">
                    <div className="col-md-8">
                    <h5 className="help-heading question" style={{color: "#fff"}}>What type of clothes do you clean?</h5>
                    </div>
                    <div className="col-md-8 answer">
                        <p>All clothes that are safe in a washer dryer. We can also wash bedding, comforters, bathroom/door mats, towels, etc. Throw it all in a bag and we
                            will take care of it for you.</p>
                    </div>
                </div>
            </div>
            <div className="container faq-container" style={{padding: "40px 20px"}}>
                <div className="row">
                    <div className="col-md-8">
                    <h5 className="help-heading question" style={{color: "#fff"}}>What temperature do you wash my clothes in?</h5>
                    </div>
                    <div className="col-md-8 answer">
                        <p>Elevated temperatures in our washes inactivate microbes and viruses on fabrics and garments. We can accommodate if you have specific
                            temperature requirements – let us know at the time of booking.</p>
                    </div>
                </div>
            </div>
            <div className="container faq-container" style={{padding: "40px 20px"}}>
                <div className="row">
                    <div className="col-md-8">
                    <h5 className="help-heading question" style={{color: "#fff"}}>What detergent and fabric softener do you use?</h5>
                    </div>
                    <div className="col-md-8 answer">
                        <p>We use Tide or Gain detergent and Downy for the softener. Please let us know if you have specific requirements around hypoallergenic, 
                            non-fragrance, babies and skin sensitives and we can accommodate as per your request upon booking.</p>
                    </div>
                </div>
            </div>
            <div className="container faq-container" style={{padding: "40px 20px"}}>
                <div className="row">
                    <div className="col-md-8">
                    <h5 className="help-heading question" style={{color: "#fff"}}>Do you use bleach when washing whites?</h5>
                    </div>
                    <div className="col-md-8 answer">
                        <p>Yes we do.</p>
                    </div>
                </div>
            </div>
        </div>
        {/* End */}
        </div>
        <Footer bg={"white"} />
  </Layout>
    )
}

export default Help;
